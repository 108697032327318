export const announces = [
    //{   date: '2025-01-10',
    //    text: '三月【用心聆聽】初階+二階工作坊03/16(日), 03/22(六), 03/23(日)開放報名中(03/10截止報名)',
    //    h: 'https://docs.google.com/forms/d/e/1FAIpQLSdKYdpxtXCjMzgPnI4jJtmKbWVjJFd8R5U_0dnB-jqon3I4QQ/viewform?usp=dialog'
    //}
    
    //{date: '2025-01-08',
    //    text: '三月【用心聆聽】初階+二階工作坊03/16(日), 03/22(六), 03/23(日)籌備中：有興趣的朋友請先空出這三天的上午時段'
    //}
    //{
    //    date: '2024-11-01',
    //    text: '十二月【從心出發】工作坊12/08, 12/15開放報名中(12/01截止)',
    //    h: 'https://docs.google.com/forms/d/e/1FAIpQLSdMXu3CwEDuDSrSBpzr5CffpuQAmfMlyZhkmd562tz7iLJQZQ/viewform'
    //},
    //{
    //    date: '2024-10-04',
    //    text: '十一月中壢夢田場初階工作坊【從心出發】11/09, 11/10開放報名中。(10/31截止)',
    //    h: 'https://docs.google.com/document/d/124oPKnQxmJ_hfXJzs2j96K1fUd7SUPxA2_k-GNm5E7Q/edit?usp=sharing'
    //},
    //{date: '2024-09-16',
    //    text: '十月華山文創場初階工作坊【從心出發】10/26, 10/27開放報名中(10/17截止)',
    //    h: 'https://docs.google.com/forms/d/e/1FAIpQLSftyf6mdf2iX0Q-Pa57XoZ3n_9qasVM1p20ZcA8PSy-Pdwh5A/viewform?usp=sf_link'
    //}
    //{date: '2024-08-18',
    //    text: '九月二階工作坊【心領神會】09/28, 09/29籌備中：有興趣的朋友請先空出09/28, 09/29上午時段',
        // h: 'https://forms.gle/dCEW3m3a1pJfj5kP7'
    //},
    //{date: '2024-08-14', 
    //    text: '九月初階工作坊【從心出發】09/14, 09/15開放報名中(8/26截止)',
    //    h: 'https://forms.gle/yquAWnrjzB1nuvys9'}
//  {date: '2024-07-31', text: '八月工作坊已確認成行，延長報名至08/06', h: 'https://forms.gle/6yQVzVvWew6vQ1eH8'},
//  {date: '2024-07-18', text: '八月工作坊籌備中：有興趣的朋友請先空出08/08, 08/22上午時段'},


]